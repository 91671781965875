<template>
    <div id="driver-image">
        <div v-if="image"><img style="width: 100%;" :src="image"></div>
        <div v-else style="color: black !important;"><FontAwesomeIcon :icon="faImage"></FontAwesomeIcon></div>
        <div class="overlay" @click="doUploadImage()">
            <div><FontAwesomeIcon :icon="faUpload"></FontAwesomeIcon></div>
        </div>
    </div>
</template>

<script setup>


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faImage,faUpload } from '@fortawesome/free-solid-svg-icons';

import {ref,computed,defineProps,defineEmits} from "vue";

const props = defineProps({
    url: {
        default: null
    },
    modelValue: {}

});


const emit = defineEmits(['update:modelValue']);

const uploadedBase64 = ref(null);

const image = computed(()=>{
    if(uploadedBase64.value != null){
        return uploadedBase64.value;
    }else if(props.url){
        return props.url;
    }else{
        return false;
    }
})
const doUploadImage = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/png, image/jpeg"; // Apenas imagens

    fileInput.addEventListener("change", async (event) => {
        const file = event.target.files[0];
        if (!file) return console.error("Nenhum arquivo selecionado");

        try {
            const resizedBase64 = await resizeAndCompressImage(file);
            uploadedBase64.value = resizedBase64;
            emit('update:modelValue', resizedBase64);
        } catch (error) {
            console.error("Erro ao processar imagem:", error);
        }
    });

    fileInput.click();
};


const resizeAndCompressImage = (file, maxWidth = 800, quality = 0.7) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;

            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // Mantém a proporção da imagem
                const scale = maxWidth / img.width;
                canvas.width = maxWidth;
                canvas.height = img.height * scale;

                // Desenha a imagem no canvas
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                
                const compressedBase64 = canvas.toDataURL("image/jpeg", quality);

                resolve(compressedBase64);
            };
        };

        reader.onerror = (error) => reject(error);
    });
};



</script>


<style scoped>
    #driver-image{
        width: 100px;
        height: 100px;
        border-radius: 100%;
        border: silver 1px solid;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .overlay{
        opacity: 0;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100px;
        height: 100px;
        background: rgba(0,0,0,0.8);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s;
    }

    .overlay:hover{
        opacity: 1;
    }
</style>